import { template as template_9c52d806154e4213b988f4c1eb9ab133 } from "@ember/template-compiler";
const FKText = template_9c52d806154e4213b988f4c1eb9ab133(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
