import { template as template_bf2d5f572466429dab43cbd26f2a8917 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_bf2d5f572466429dab43cbd26f2a8917(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
